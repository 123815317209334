import React from 'react'
import Sidebar from '../Sidebar/Sidebar'

const Reportmanagement = () => {
  return (
    <>
    <Sidebar/>
    <div>
        <h1 className='text-black'>Report Management</h1></div>
        </>
  )
}

export default Reportmanagement