import React from 'react'
import Sidebar from '../Sidebar/Sidebar'

const Transactiondataentry = () => {
  return (
    <>
    <Sidebar/>
    <div><h1  className='text-black'>Transactiondataentry</h1></div>
    </>
  )
}

export default Transactiondataentry