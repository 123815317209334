import React from 'react'

const Reconciliationmanagement = () => {
  return (
    <div>      <h1 className='text-black'style={{marginLeft:'50px'}}>Reconciliation Management</h1>
    <p>This is the content for Reconciliation Management.</p>
</div>
  )
}

export default Reconciliationmanagement;